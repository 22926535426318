<template>
    <div class="app-body">
        <div class="nav-title">专题课程</div>
        <van-pull-refresh v-model="list.pullloading" @refresh="onLoad(true)" success-text="刷新成功">
            <van-list v-model="list.loading" @load="onLoad" 
                :finished="list.data.length >= list.total" finished-text="没有更多了" 
                :error.sync="list.error" error-text="请求失败，点击重新加载"
                >
                <div class="list-area">
                    <div v-for="(row, index) in list.data" :key="index" class="list-row" @click="toDetail(row.id)">
                        <van-image fit="cover" lazy-load :src="row.cover"/>
                        <div class="title">
                            {{ row.title }}
                        </div>
                        <div class="limit">
                            {{ row.playtime | formatTime }}
                        </div>
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>
        <div style="height: 13vw;"></div>
        <foot></foot>
    </div>
</template>

<script>
import Vue from 'vue';
import { Lazyload, Notify } from 'vant';
import Foot from "@/components/Foot";
import { topicsList } from '@/api/topics';
import moment from 'moment';

Vue.use(Lazyload);

export default {
    components: { 
        Foot
    },
    filters: {
        formatTime(seconds) {
            return moment.utc(seconds * 1000).format('HH:mm:ss');
        }
    },
    data() {
        return {
            list: {
                loading: false,
                pullloading: false,
                data: [],
                page: 0,
                limit: 10,
                total: 1,
                error: false,
            },
        }
    },
    created() {
        // this.onLoad()
    },
    mounted() {

    },
    methods: {
        async onLoad(isinit) {
            // console.log(isinit);
            if (isinit) {
                this.list.pullloading = true;
                this.list.page = 0;
            } else {
                this.list.loading = true;
            }

            this.list.page += 1;

            try {
                
                const { data } = await topicsList({
                    page: this.list.page.toString(),
                    limit: this.list.limit 
                });
                
                this.list.total = data.total;

                if (isinit) {
                    this.list.data = data.rows;
                    this.list.pullloading = false;
                } else {
                    this.list.data.push(...data.rows);
                    this.list.loading = false;
                }
            } catch (e) {
                // console.log(e.message);
                this.list.error = true;

                this.list.page -= 1;
                this.list.pullloading = false;
                this.list.loading = false;
            }
        },
        toDetail(id) {
            this.$router.push({
                path: '/topics_detail',
                query: {
                    id: id
                }
            })
        }
    },
};
</script>
<style>
html, body, .app-main {
    height: 100%;
}
</style>
<style lang="less" scoped>
.app-body {
    background-color: #fafafa;
    padding-top: 1.9vh;
    height: 100%;
}
.nav-title {
    border-left: .5vh solid #5380ff;
    font-weight: bold;
    margin: 0 1.9vh 1.9vh;
    padding-left: 1vh;
}
.list-area {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1.9vh;
    padding: 0 1.9vh;
    .list-row {
        width: calc((100vw - 1.9vh*3) / 2) ;
        height: calc((100vw - 1.9vh*3) / 2.3) ;
        overflow: hidden;
        border-radius: 1vh;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1vh;
        padding-bottom: 1vh;
        box-shadow: 0 0.3vh 1vh #eeeeee;
        // font-size: 1.9vh;
        font-size: 3.7vw;
        .van-image {
            height: 65%;
            width: 100%;
            position: relative;
            &:after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: url('../../assets/img/icon_bofang_1@2x.png') no-repeat center center;
                // background-size: auto 3.2vh;
                background-size: auto 6vw;
                background-color: rgba(0,0,0,.4);
                z-index: 1;
            }
        }
        .title {
            padding: 0 1vh;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .limit {
            padding: 0 1vh;
            // background: url('../../assets/img/icon_shichang@2x.png') no-repeat 1vh center;
            background: url('../../assets/img/icon_shichang@2x.png') no-repeat 1vw center;
            background-size: auto 100%;
            color: #999999;
            // padding-left: 3.5vh;
            padding-left: 6.1vw;
        }
    }
}
</style>
